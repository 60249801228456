<template>
  <v-dialog v-model="rankDialog" persistent>
    <v-card class="">
      <p class="maincolor-bg subtitle-2 text-center pa-3">ランクごとに素敵な特典がもらえます</p>
      <div class="px-3">
        <h4 class="subtitle-1 font-weight-bold">ランクについて</h4>
      <v-divider class="my-2"></v-divider>
        <p class="caption mb-10" v-if="item" v-html="item.rank_detail[0].rank_description"></p>
      <h4 class="subtitle-1 font-weight-bold">ランク一覧</h4>
      <v-divider class="my-2"></v-divider>

      <template v-if="item">
        <div class="" v-for="rank in item.rank_detail" :key="rank.id">
          <v-row>
            <v-col cols="3" v-if="rank.rank_img_path">
              <v-img v-if="rank.rank_img_path" :src="rank.rank_img_path" lazy-src="@/assets/noimg@2x.png" width="50" height="50">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5" :width="2"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-img v-else src="@/assets/noimg@2x.png" lazy-src="@/assets/noimg@2x.png" width="50" height="50">
              </v-img>
            </v-col>
            <v-col cols="9">
              <h5 class="subtitle-1 font-weight-bold" v-if="rank.rank_str">{{rank.rank_str}}</h5>
              <p class="fontsize11 mb-1">{{rank.rank_condition}}</p>
              <v-chip outlined x-small class="mb-2" style="color:#9D9D9D; border-color: #9D9D9D;">ランク特典</v-chip>
              <div v-if="rank.amenity_type == 1">
                <p class="subtitle-2 ma-0">
                  ランクコンプリートチケット<br>
                  {{rank.complete_ticket_title}}
                </p>
              </div>
              <div v-else>
                <p class="subtitle-2 ma-0" v-html="rank.benefit_info"></p>
              </div>

            </v-col>
          </v-row>
          <v-divider class="mt-4 mb-6"></v-divider>
        </div>
      </template>

      <div class="text-center">
        <v-btn rounded depressed class="caption font-weight-black mb-10 w80" style="background-color:#EFEFEF;" @click="close()">閉じる</v-btn>
      </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['item'],
  data() {
    return {
      rankDialog: false
    };
  },
  methods: {
    open() {
      this.item.rank_detail[0].rank_description = this.item.rank_detail[0].rank_description.replace(/\n/g, '<br/>');
      this.rankDialog = true;
    },
    close() {
      this.rankDialog = false;
    }
  }
}
</script>
