<template>
  <v-card class="mb-12 pa-4">
    <v-row no-gutters align="center">
      <v-col cols="4">
        <h2 class="overline ma-0">現在のランク</h2>
      </v-col>
      <v-col cols="8">
        <p class="subtitle-1 font-weight-bold ma-0">{{item.rank_text}}</p>
      </v-col>
    </v-row>

    <v-divider class="my-3"></v-divider>

    <v-row no-gutters align="center" justify="space-between">
      <v-col cols="3">
          <v-img v-if="item.rank_img_path" :src="item.rank_img_path" lazy-src="@/assets/noimg@2x.png">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="grey lighten-5" :width="2"></v-progress-circular>
              </v-row>
            </template>
					</v-img>
      </v-col>
      <v-col cols="8">
        <p v-if="item.rankmax_flg == 0" class="caption text-center mb-1">次のランクまであと{{item.rank_remaining}}point</p>
        <!-- 金額表示は現時点で使用しないのでコメントアウト -->
        <!-- <p class="fontsize11 maincolor text-center mb-1" style="line-height:1;">現在までのご利用金額 <span class="subtitle-1 font-weight-bold">{{item.total_amount}}</span>円</p> -->
        <v-btn rounded depressed class="maincolor-btn2 w100" @click="open">ランクについて</v-btn>
      </v-col>
    </v-row>
  </v-card>
  
</template>

<script>
export default {
  props: ['item'],
  data() {
    //NEW_DEV-1723 cyber start
    if(this.item.rank == this.item.rank_detail.length){
      this.item.rankmax_flg = 1
    }else{
      this.item.rankmax_flg = 0
    }
    //NEW_DEV-1723 cyber end
    return {
      rankDialog: false
    };
  },
  methods: {
    open() {
      this.$emit('openDialog');
    },
    close() {
      this.rankDialog = false;
    }
  }
}
</script>