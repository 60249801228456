<template>
  <v-content>
    <div class="body-2 mb-6 px-4 py-2 font-weight-bold" style="background-color: #E4E4E4; color: #333333;">
      ユーザー情報
    </div>

    <v-row no-gutters class="px-4 mb-6">
      <v-col cols="7">
        <v-row no-gutters>
          <v-col cols="3">
            <p class="caption" style="line-height:19px; color:#9D9D9D;">ID</p>
          </v-col>
          <v-col cols="9">
            <p class="body-2 font-weight-bold" v-if="history && history.user_id" style="line-height:19px;">{{history.user_id}}</p>
          </v-col>
          <v-col cols="3">
            <p class="caption" style="line-height:24px; color:#9D9D9D;">name</p>
          </v-col>
          <v-col cols="9">
            <p class="body-1 font-weight-bold" style="line-height:24px;" v-if="history && history.user_name">{{history.user_name}} <span class="fontsize11">さん</span></p>
            <p class="body-1 font-weight-bold" style="line-height:24px;" v-else>ゲスト <span class="fontsize11">さん</span></p>
          </v-col>
          <v-col cols="12">
            <p class="overline" style="">ポイント取得の際は、QRコードをスタッフへご提示ください</p>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="4" @click="showQRDialog()">
        <qrcode :value="qrcodeurl" :options="{ width: 100, margin: 1 }"></qrcode>
      </v-col>
    </v-row>

    <div class="body-2 mb-4 px-4 py-2 font-weight-bold" style="background-color: #E4E4E4; color: #333333;">
      ポイントについて
    </div>

    <div class="px-4 mb-6 caption" v-if="history && history.point_detail" v-html="history.point_detail"></div>

    <div class="body-2 mb-4 px-4 py-2 font-weight-bold" style="background-color: #E4E4E4; color: #333333;">
      <v-row no-gutters>
        <v-col cols="5">
          ポイント履歴
        </v-col>
        <v-col class="text-right" style="font-size: 10px; font-weight: normal;">
          最後にご利用した月から3ヵ月間を表示
        </v-col>
      </v-row>
    </div>

    <div class="mb-4 px-4" v-if="history && history.data !== ''">
      <v-row no-gutters align="center" style="border-bottom: solid 1px #9D9D9D;" v-for="item in history.data" :key="item.id">
        <v-col cols="5" class="fontsize13">
          {{item.create_date}}
        </v-col>
        <v-col cols="7" class="text-right title font-weight-bold">
          {{item.point}}<span class="subtitle-2">point</span>
        </v-col>
      </v-row>
    </div>

    <div class="mb-4 px-4" v-else>
      <p class="body-2">ポイント履歴はありません。</p>
    </div>

    <div class="body-2 mb-4 px-4 py-2 font-weight-bold" style="background-color: #E4E4E4; color: #333333;">
      お使いのスマートフォンについて
    </div>

    <div class="px-4 mb-6 fontsize13">
      現在ご使用中のスマートフォンにて、機種変更ならびにアプリの再インストールをお考えのお客様へ。<br>
      同じOSへの変更の場合も異なるOSへの変更の場合も、現在取得しているポイントは引き継ぐことが可能です。
    </div>


    <!-- QR codeについてダイアログ -->
    <QRDialog ref="qrDialog" :qrdata="qrcodeurl"></QRDialog>
  </v-content>
</template>

<script>
import QRDialog from '@/views/point/QRDialog.vue'
export default {
  components: {
    QRDialog
  },
  data () {
    if (history.point_detail){
      this.history.point_detail = this.history.point_detail.replace(/\n/g, '<br/>');
    }
    return {
      qrcodeurl: null,
      //NEW_DEV-1701 cyber start
      minute: 5,
      second: 0,
      selectTab: "user",
      //NEW_DEV-1701 cyber end
    }
  },
  props: ['history', 'init'],
	methods: {
    showQRDialog() {
      this.$refs.qrDialog.open();
    },
    //NEW_DEV-1701 cyber start
    timer() {
      var self = this
        clearInterval(this.promiseTimer)
        this.promiseTimer = setInterval(function () {
          if (self.minute !== 0 && self.second === 0) {
            self.second = 59
            self.minute -= 1
          } else if (self.minute === 0 && self.second === 0) {
            self.second = 0
            self.minute = 0
            if(self.selectTab === "user"){
              self.callDialog('エラー', 'QRコードの有効期限が切れました。再度表示しなおしてください。', 2);
            }
            clearInterval(self.promiseTimer)
          } else {
            self.second -= 1
          }
        }, 1000)
    },
    setTabName(val){
      this.selectTab = val;
      if (this.minute === 0 && this.second === 0 && this.selectTab === "user") {
        this.callDialog('エラー', 'QRコードの有効期限が切れました。再度表示しなおしてください。', 2);
      }
    }
    //NEW_DEV-1701 cyber end
  },
  mounted() {
    //NEW_DEV-1701 cyber start
    this.timer();
    //NEW_DEV-1701 cyber end
    //NEW_DEV-998 cyber start
    this.qrcodeurl = "https://" + location.host + "/points/" + this.init.user_id + "/edit?shopId=" + this.init.sid + "&shopName=" + this.init.shop_name + "&nowDate=" + Date.parse(new Date());
    //NEW_DEV-998 cyber end
    console.log(this.qrcodeurl)
  },
}
</script>
