<template>
  <v-dialog v-model="rankUpDialog" content-class="rankupdialog">
    <v-card class="">
      <v-card-title class="body-2 maincolor-bg justify-center">
        <h1 class="subtitle-1 font-weight-bold mb-3">ランクアップしました!</h1>
        <p class="subtitle-2 text-center pa-0 ma-0" style="width:100%;">あなたの現在のランク</p>
        <h2 class="title font-weight-bold text-center pa-0" style="width:100%;" v-if="item && item.rank_str">{{item.rank_str}}</h2>
      </v-card-title>
      <p class="subtitle-2 text-center pa-0 my-3" style="width:100%;" v-if="item && item.point_type == 1">現在のご利用可能ポイント</p>
      <p class="subtitle-2 text-center pa-0 my-3" style="width:100%;" v-if="item && item.point_type == 2 && item.rank_str">{{item.rank_str}}ランクのコンプリートはこちら</p>
      <img src="@/assets/point/rankup_bar.svg" width="100%">
      <p class="subtitle-1 text-center px-4 my-3" style="width:100%;" v-if="item && item.point_type == 1">
        <span class="bigpoint">{{item.current_point}}</span><br />
        <span class="xsmallpoint">point</span>
      </p>
      <!-- NEW_DEV-1725 cyber start -->
      <p class="subtitle-1 text-center px-4 my-3" style="width:100%;" v-if="item && item.point_type == 2" v-html="item.ticket_title"></p>
      <!-- NEW_DEV-1725 cyber end -->
      <div class="text-center pb-5">
        <v-btn rounded class="maincolor-bg default_button" @click.stop="close()">閉じる</v-btn>
      </div>

      <div class="rankimg" v-if="item && item.rank_img_path">
        <img :src="item.rank_img_path" width="50">
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['item'],
  data: () => ({
    rankUpDialog: false,
  }),
  methods: {
    open() {
      this.rankUpDialog = true;
    },
    close() {
      this.rankUpDialog = false;
    }
  }
}
</script>

<style scope>
.bigpoint {
  font-size: 50px;
  font-weight: bold;
  font-family: "Oswald", sans-serif !important;
  color: #333333;
  line-height: 1;
}
.xsmallpoint {
  font-size: 24px;
  font-weight: bold;
  font-family: "Oswald", sans-serif !important;
  color: #333333;
}
.rankimg {
  width: 50px;
  position: absolute;
  top: -10px;
  left: 10px;
}
.rankupdialog {
  overflow-y: visible !important;
}
</style>
